/**
 * Copyright ©2023 Drivepoint
 */

import {Store} from "@d4lton/node-frontend";
import State from "../state/State";
import {StateEvent} from "../state/StateEvent";
import Logger from "@utilities/logger/Logger";
import WebAppServerClient from "../clients/WebAppServerClient";

const logger = Logger.logger;

export default class ExcelCompanyStore extends Store {

  private _company?: any;

  init(): void {
    State.register("excel_company", (event: StateEvent) => {
      this.value = [];
      this.value = event.value;
    });
  }

  async update(data: any): Promise<any> {
    return this.action(async () => {
      return await WebAppServerClient.request(`/ui/excelCompany/${this.value.id}`, "PATCH", data);
    });
  }

  async upgradeProspectCompany() {
    return this.action(async () => {
      const tier = "beta"
      return await WebAppServerClient.request(`/ui/company/${this.value.id}/upgradeCompany`, "POST", {tier});
    });
  }

}
