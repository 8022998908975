/**
 * Copyright ©2023 Drivepoint
 */

import React from "react";
import {ClickAwayListener, Tooltip} from "@mui/material";
import {TooltipProps} from "@mui/material/Tooltip/Tooltip";
import Logger from "@utilities/logger/Logger";
import "./DPTooltip.less";

const logger = Logger.logger;

export default function DPTooltip(props: TooltipProps): any {

  function onClickAway(event: any): void {
    if (props.onClose) { props.onClose(event); }
  }

  return <div className="dp-tooltip">
    <ClickAwayListener onClickAway={onClickAway}>
      <Tooltip
        arrow
        PopperProps={{disablePortal: true}}
        disableFocusListener={true}
        disableHoverListener={true}
        disableTouchListener={true}
        {...props}
      >
        {props.children}
      </Tooltip>
    </ClickAwayListener>
  </div>;

}
