/**
 * Copyright ©2022 Drivepoint
 */

import React from "react";
import {createRoot} from "react-dom/client";
import App from "./main/App/App";
import Logger from "./utilities/logger/Logger";
import Licenses from "./main/Licenses";
import LaunchDarklyClient from "./services/clients/LaunchDarklyClient";
import Telemetry from "./services/telemetry/Telemetry";
import Firebase from "./services/firebase/Firebase";
import ServiceRegistry from "./services/ServiceRegistry";
import GoogleCharts from "./services/google/GoogleCharts";
import "./index.css";
import DynamicImportService from "./services/DynamicImportService";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
// import "./branding/test-company.css";

// required for any libraries that need window.global, since Vite doesn't provide it (Joyride, for example)
declare global { interface Window {global: any} }
window.global ||= window;

(async () => {

  dayjs.extend(quarterOfYear);

  Logger.init();
  await DynamicImportService.init();
  await Licenses.init();
  await LaunchDarklyClient.init();
  await Telemetry.init();
  await Firebase.init();
  await ServiceRegistry.init();
  await GoogleCharts.init();
  createRoot(document.getElementById("root")!).render(<App />);
})();
