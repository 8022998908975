/**
 * Copyright ©2022 Drivepoint
*/

import React, {useEffect, useState} from "react";
import {IconButton, Tooltip} from "@mui/material";
import {Upgrade} from "@mui/icons-material";
import compareVersions from "compare-versions";
import {EventBus} from "@services/eventbus/EventBus";
import {Config} from "@d4lton/node-frontend";

export default function VersionUpdatedButton(props: any): any {

  const [minimumClientVersion, setMinimumClientVersion] = useState<string>();
  const [visible, setVisible] = useState<boolean>(false);
  const [serverStatus, setServerStatus] = useState<any>();

  useEffect(() => {
    const registration = EventBus.register("server:status", setServerStatus);
    return () => {
      EventBus.unregister(registration);
    };
  }, []);

  useEffect(() => {
    setMinimumClientVersion(serverStatus?.client?.minimum_version);
  }, [serverStatus]);

  useEffect(() => {
    if (minimumClientVersion) {
      setVisible(needsUpdate());
    } else {
      setVisible(false);
    }
  }, [minimumClientVersion]);

  function needsUpdate(): boolean {
    const version = Config.get("version");
    if (version && minimumClientVersion) {
      return compareVersions(version, minimumClientVersion) < 0;
    }
    return false;
  }

  return <Tooltip title="New version available, click to update!">
    <IconButton sx={{display: visible ? "flex" : "none"}} onClick={() => window.location.reload()}>
      <Upgrade />
    </IconButton>
  </Tooltip>;

}
