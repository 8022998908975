/**
 * Copyright ©2022 Drivepoint
 */

import React, {useEffect, useState} from "react";
import {
  Avatar, Box, Dialog, DialogActions, DialogContent, DialogTitle,
  IconButton, ListItem, ListItemText, Typography
} from "@mui/material";
import {AccountCircle, Brightness4, Brightness7, Check, Info, Login, Logout, LockResetOutlined} from "@mui/icons-material";
import {useStore, Config} from "@d4lton/node-frontend";
import useThemeContext from "@hooks/useThemeContext";
import ThemedDefaultButton from "../styled/ThemedDefaultButton";
import {useNavigate} from "react-router-dom";
import PrimaryIcon from "../styled/PrimaryIcon";
import BrandedDivider from "../styled/BrandedDivider";
import ThemedMenuItem from "../styled/ThemedMenuItem";
import BrandedMenu from "../styled/BrandedMenu";
import ServiceRegistry from "@services/ServiceRegistry";
import Telemetry from "@services/telemetry/Telemetry";
import Firebase from "@services/firebase/Firebase";
import Logger from "@utilities/logger/Logger";
import useStateChange from "@hooks/useStateChange";
import BainbridgeUser from "@services/user/BainbridgeUser";
import {EventBus} from "@services/eventbus/EventBus";
import usePage from "@hooks/usePage";
import {English} from "@utilities/English";
import ExcelCompanyStore from "@services/store/ExcelCompanyStore";
import DPTooltip from "@components/DPTooltip/DPTooltip";
import DPSubMenu from "@components/DPSubMenu/DPSubMenu";

const logger = Logger.logger;

type NotificationProps = {
  type: "error" | "success";
  msg: string;
};

export default function AvatarButton(): any {

  const navigate = useNavigate();
  const page = usePage();
  const {ThemeContext} = useThemeContext();
  const company = useStateChange<any>("company");
  const [excelCompany] = useStore<any, ExcelCompanyStore>(ExcelCompanyStore);
  const user = useStateChange<BainbridgeUser>("user");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [serverStatus, setServerStatus] = useState<any>();
  const [showToolip, setShowTooltip] = useState(false);

  useEffect(() => {
    const registration = EventBus.register("server:status", setServerStatus);
    return () => {
      EventBus.unregister(registration);
    };
  }, []);

  function getUserFullName(): string {
    if (user?.excelUser) {
      return `${user?.excelUser.firstName} ${user?.excelUser.lastName}`;
    } else {
      return "";
    }
  }

  function getUserAvatar(): any {
    let avatar;
    const fullName = getUserFullName();
    if (user?.user?.photoURL) {
      avatar = <Avatar alt={fullName} aria-label={fullName} src={user?.user?.photoURL} sx={{width: 32, height: 32}} />;
    } else {
      avatar = <AccountCircle />;
    }
    return avatar;
  }

  function show(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function hide() {
    setAnchorEl(null);
  }

  function showAbout() {
    hide();
    setOpen(true);
  }

  function signIn() {
    hide();
    navigate("/sign_in");
  }

  function signOut() {
    hide();
    Firebase.signOut().then(() => navigate("/"));
  }

  function action(item: any, themeContext?: any) {
    Telemetry.track(`avatar_item_${item}`);
    switch (item) {
      case "theme":
        themeContext.toggle();
        hide();
        break;
      case "about":
        return showAbout();
      case "sign_in":
        return signIn();
      case "sign_out":
        return signOut();
    }
  }

  function fullName(): any {
    if (user?.excelUser) {
      return `${user.excelUser.firstName} ${user.excelUser.lastName}`;
    }
  }

  function handleResetPassword() {
    Firebase.handleResetPassword().then(() => {
      EventBus.dispatch({
        type: "toast",
        toast: {"subtitle": "Email sent, check your inbox.", "severity": "success"}
      } as any);
    }).catch((error) => {
      EventBus.dispatch({
        type: "toast",
        toast: {"subtitle": `${error.code}`, "severity": "error"}
      } as any);
    });
    hide();
  }

  function isUserWithPassword() {
    return Firebase.user?.providerData.find(provider => provider.providerId === "password");
  }

  function renderAccountItem(): any {
    if (user) {
      return <div>
        <ListItem>
          <Box sx={{width: "100%", margin: "2rem 0"}}>
            <Box sx={{textAlign: "center", margin: "0.25rem", fontSize: "1.5rem"}}>{fullName()}</Box>
            <Box sx={{textAlign: "center", margin: "0.25rem", fontSize: "0.75rem"}}>{user.user.email}</Box>
            <Box sx={{textAlign: "center", margin: "0.25rem"}}>{excelCompany?.name}</Box>
          </Box>
        </ListItem>
        <BrandedDivider sx={{margin: "8px 0"}} />
      </div>;
    }
  }

  function renderSignInOrSignOutItem(): any {
    if (user) {
      return <ThemedMenuItem onClick={() => action("sign_out")}>
        <PrimaryIcon><Logout /></PrimaryIcon>
        <ListItemText>Sign Out</ListItemText>
      </ThemedMenuItem>;
    } else {
      return <ThemedMenuItem onClick={() => action("sign_in")}>
        <PrimaryIcon><Login /></PrimaryIcon>
        <ListItemText>Sign In</ListItemText>
      </ThemedMenuItem>;
    }
  }

  function renderToggleThemeItem(themeContext: any): any {
    return <ThemedMenuItem onClick={() => action("theme", themeContext)}>
      <PrimaryIcon>{themeContext.mode === "dark" ? <Brightness7 /> : <Brightness4 />}</PrimaryIcon>
      <ListItemText>Toggle light/dark theme</ListItemText>
    </ThemedMenuItem>;
  }

  function renderResetPasswordItem(): any {
    return  <DPTooltip open={showToolip} title={!isUserWithPassword() ? "Reset Password is not available because you have signed in using Google" : null}>
      <span onMouseOver={() => {setShowTooltip(true);}} onMouseLeave={() => {setShowTooltip(false);}}>
        <ThemedMenuItem onClick={handleResetPassword} disabled={!isUserWithPassword()}>
          <PrimaryIcon><LockResetOutlined /></PrimaryIcon>
          <ListItemText>Send Password Reset Email</ListItemText>
        </ThemedMenuItem>
      </span>
    </DPTooltip>;
  }

  function renderAboutItem(): any {
    return <ThemedMenuItem onClick={() => action("about")}>
      <PrimaryIcon><Info /></PrimaryIcon>
      <ListItemText>About...</ListItemText>
    </ThemedMenuItem>;
  }

  function switchCompany(id: string): void {
    Object.keys(sessionStorage).forEach(key => {
      if (key.includes("drivepoint_plan")) {
        sessionStorage.removeItem(key);
      }
    });
    hide();
    const defaultPage = page.current || ServiceRegistry.routeService.defaultPage;
    let path = defaultPage.path;
    const tokens = ServiceRegistry.routeService.getPathTokens(path);
    for (const token of tokens) {
      switch (token) {
        case ":company":
          path = path.replace(token, id);
          break;
      }
    }
    ServiceRegistry.companyService.setCompanyById(id);
    window.location.assign(path);
  }

  function renderCompanies(): any {
    if (!user) { return; }
    if (!Array.isArray(user?.companies)) { return; }
    if (user.companies.length < 2) { return; }
    const items = user.companies
      .sort((a: any, b: any) => English.baseProperNoun(a.name).localeCompare(English.baseProperNoun(b.name)))
      .map((it: any) => {
        const selected = it.id === company?.id;
        return <ThemedMenuItem key={`company_${it.id}`} className={selected ? "selected" : ""} onClick={() => switchCompany(it.id)}>
          <PrimaryIcon style={{opacity: selected ? "1.0" : "0"}}><Check /></PrimaryIcon>
          <ListItemText>{it.name}</ListItemText>
        </ThemedMenuItem>;
      });
    return <DPSubMenu label="Companies" onClose={hide} open={!!anchorEl}>
      {items}
    </DPSubMenu>
  }

  function renderMenu(themeContext: any): any {
    return <BrandedMenu open={!!anchorEl} onClose={hide} anchorEl={anchorEl}>
      {renderAccountItem()}
      {renderCompanies()}
      {renderToggleThemeItem(themeContext)}
      <BrandedDivider />
      {renderAboutItem()}
      <BrandedDivider />
      {renderResetPasswordItem()}
      <BrandedDivider style={{margin: "8px 0"}} />
      {renderSignInOrSignOutItem()}
    </BrandedMenu>;
  }

  function renderAboutDialog(): any {
    return <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth={true}>
      <DialogTitle sx={{userSelect: "none"}}>About</DialogTitle>
      <DialogContent>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Typography sx={{width: "6rem", textAlign: "right", marginRight: "1rem", fontColor: "#404040"}}>Web App:</Typography>
            <Typography sx={{width: "5rem"}}>v{Config.get("version")}</Typography>
            <Typography sx={{fontSize: "0.75rem", color: "#808080", marginLeft: "1rem"}}>{Config.get("hash")}</Typography>
          </Box>
          <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Typography sx={{width: "6rem", textAlign: "right", marginRight: "1rem", fontColor: "#404040"}}>Server:</Typography>
            <Typography sx={{width: "5rem"}}>v{serverStatus ? serverStatus.version : "(N/A)"}</Typography>
            <Typography sx={{fontSize: "0.75rem", color: "#808080", marginLeft: "1rem"}}>{serverStatus?.hash ? serverStatus.hash : "(N/A)"}</Typography>
          </Box>
        </Box>
        <div style={{fontSize: "0.75em", textAlign: "center", paddingTop: "1em", color: "#808080"}}>
          Copyright &copy;2021-{new Date().getFullYear()} Drivepoint
        </div>
      </DialogContent>
      <DialogActions>
        <ThemedDefaultButton onClick={() => setOpen(false)}>Done</ThemedDefaultButton>
      </DialogActions>
    </Dialog>;
  }

  return <ThemeContext.Consumer>
    {(themeContext: any) => {
      return <>
        <IconButton onClick={show} size="large" color="inherit">
          {getUserAvatar()}
        </IconButton>
        {renderMenu(themeContext)}
        {renderAboutDialog()}
      </>;
    }}
  </ThemeContext.Consumer>;

}
