/**
 * Copyright ©2022 Drivepoint
 */

import * as React from "react";
import useThemeContext from "@hooks/useThemeContext";
import UserNameAndCompany from "../UserNameAndCompany/UserNameAndCompany";
import AvatarButton from "../AvatarButton";
import Logger from "@utilities/logger/Logger";
import "./UserBox.less";

const logger = Logger.logger;

export default function UserBox(props: any): any {

  const {ThemeContext} = useThemeContext();

  function getUserBox(): any {
  }

  return <div className="user-box">
    <UserNameAndCompany />
    <AvatarButton ThemeContext={ThemeContext} />
  </div>;
};
