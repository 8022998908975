/**
 * Copyright ©2022 Drivepoint
 */

import React, {useEffect, useState, Suspense} from "react";
import {BrowserRouter} from "react-router-dom";
import {Box, ThemeProvider} from "@mui/material";
import useThemeContext from "@hooks/useThemeContext";
import Routing from "../Routing/Routing";
import NavigationDrawer from "../navigation/NavigationDrawer/NavigationDrawer";
import TopAppBar from "../TopAppBar";
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs";
import VersionUpdatedButton from "../../widgets/component/VersionUpdatedButton";
import UserBox from "../../widgets/component/UserBox/UserBox";
import Suspended from "../../widgets/component/Suspended";
import Logger from "@utilities/logger/Logger";
import DPNotifications from "@components/DPNotifications/DPNotifications";
import BreadcrumbManager from "@services/BreadcrumbManager";
import "./App.less";

const logger = Logger.logger;

export default function App(): JSX.Element {

  const {themeProvider, ThemeContext, theme} = useThemeContext();

  const [navigationOpen, setNavigationOpen] = useState<boolean>(getNavigationDrawerState());

  useEffect(() => {
    setNavigationDrawerState();
  }, [navigationOpen]);

  function getNavigationDrawerState(): boolean {
    const state = localStorage.getItem("navigationDrawerState");
    return state != null ? JSON.parse(state) : true;
  }

  function setNavigationDrawerState(): void {
    localStorage.setItem("navigationDrawerState", JSON.stringify(navigationOpen));
  }

  function toggleNavigationDrawer(): void {
    setNavigationOpen(!navigationOpen);
  }

  function renderNavigationDrawerToggle(): any {
  }

  return <ThemeContext.Provider value={themeProvider}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>

        <Box id="main" component="main" className="app desktop">

          {/* left-hand navigation */}
          <NavigationDrawer open={navigationOpen} toggle={toggleNavigationDrawer} resizable />

          <Box className="app-content" component="section">

            {/* app bar */}
            <TopAppBar open={navigationOpen}>
              {renderNavigationDrawerToggle()}
              <Breadcrumbs ref={BreadcrumbManager.ref} />
              <Box sx={{flexGrow: 1}} />
              <VersionUpdatedButton />
              <UserBox />
            </TopAppBar>

            {/* main content */}
            <Suspense fallback={<Suspended />}>
              <Routing />
            </Suspense>

          </Box>

        </Box>

      </BrowserRouter>

      <DPNotifications />

    </ThemeProvider>
  </ThemeContext.Provider>;

}
