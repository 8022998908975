/**
 * Copyright ©2022 Dana Basken
 */

import {Config} from "@bainbridge-growth/common-ts";
import {LoggerLevel, LoggerLevelFromString} from "./LoggerLevel";

declare global {
  interface Window {Logger: typeof Logger, LoggerLevel: typeof LoggerLevel}
}

export default class Logger {

  private static _logger: Logger = new Logger();
  private static _level: LoggerLevel = LoggerLevel.ERROR;
  private static _excludes: string[] = [];

  static init(): void {
    window.Logger = Logger;
    window.LoggerLevel = LoggerLevel;
    Logger.level = LoggerLevelFromString(Config.get("logger.level", "INFO"));
    const excludes: string[] | undefined = Config.get("logger.excludes");
    excludes?.forEach(name => Logger.exclude(name));
  }

  fatal(...args: any[]): void {
    console.error(...Logger.buildPrefix("FATAL"), ...args);
  }

  error(...args: any[]): void {
    if (Logger._level < LoggerLevel.ERROR) { return; }
    console.error(...Logger.buildPrefix("ERROR"), ...args);
  }

  warn(...args: any[]): void {
    if (Logger._level < LoggerLevel.WARN) { return; }
    console.warn(...Logger.buildPrefix("WARN"), ...args);
  }

  info(...args: any[]): void {
    if (Logger._level < LoggerLevel.INFO) { return; }
    console.log(...Logger.buildPrefix("INFO"), ...args);
  }

  debug(...args: any[]): void {
    if (Logger._level < LoggerLevel.DEBUG) { return; }
    if (Logger.isExcluded()) { return; }
    console.log(...Logger.buildPrefix("DEBUG", "#006ee4"), ...args);
  }

  trace(...args: any[]): void {
    if (Logger._level < LoggerLevel.TRACE) { return; }
    if (Logger.isExcluded()) { return; }
    console.log(...Logger.buildPrefix("TRACE", "green"), ...args);
  }

  stacktrace(...args: any[]): void {
    if (Logger._level < LoggerLevel.TRACE) { return; }
    console.trace(...Logger.buildPrefix("TRACE", "green"), ...args);
  }

  static buildPrefix(level: string, color?: string): string[] {
    const now = new Date().toLocaleTimeString();
    return color ? [`%c[${now}%c] [${level}]%c`, "color: gray", `color: ${color}`, "color: inherit"] : [`%c[${now}%c] [${level}]`, "color: gray", "color: inherit"];
  }

  static isExcluded(depth: number = 3): boolean {
    if (!Logger._excludes?.length) { return false; }
    const error = new Error();
    if (!error.stack) { return false; }
    const name = error.stack.split("\n").slice(depth).map(it => it.match(/at ([a-zA-Z]+)(.+?)/)?.[1])[0] || "";
    return Logger._excludes.includes(name);
  }

  static get level(): LoggerLevel {
    return Logger._level;
  }

  static set level(value: LoggerLevel) {
    Logger._level = value;
  }

  static exclude(name: string): void {
    Logger._excludes.push(name);
  }

  static get logger(): any {
    return Logger._logger;
  }

}
