/**
 * Copyright ©2023 Drivepoint
 */

import React, {ReactNode, useEffect, useState} from "react";
import {ChevronLeft} from "@mui/icons-material";
import {ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";

import "./DPSubMenu.less"

interface SubMenuProps {
  label: string;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

export default function DPSubMenu(props: SubMenuProps) {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inItem, setInItem] = useState<boolean>(false);
  const [inMenu, setInMenu] = useState<boolean>(false);
  const [timer, setTimer] = useState<any>();
  const [itemsEl, setItemsEl] = useState<null | HTMLElement>();

  useEffect(() => {
    clearTimeout(timer);
    if (!inItem && !inMenu) {
      setTimer(setTimeout(() => {
        if (!inItem && !inMenu) { hide(); }
      }, 250));
    }
  }, [inItem, inMenu]);

  useEffect(() => {
    const visible = !!anchorEl && props.open && !!itemsEl;
    if (visible) {
      itemsEl?.querySelector(".selected")?.scrollIntoView({behavior: "smooth", block: "center"});
    }
  }, [anchorEl, props.open, itemsEl]);

  function hide() {
    clearTimeout(timer);
    setAnchorEl(null);
    setInMenu(false);
    setInItem(false);
  }

  function close() {
    hide();
    props.onClose();
  }

  function onMenuItemEnter(event: any): void {
    setAnchorEl(event.target);
    setInItem(true);
  }

  return <div>
    <MenuItem className="dp-parent-item" onMouseEnter={onMenuItemEnter} onMouseLeave={() => setInItem(false)}>
      <ListItemIcon className="dp-icon"><ChevronLeft /></ListItemIcon>
      <ListItemText>{props.label}</ListItemText>
    </MenuItem>
    <Menu
      className="dp-submenu"
      style={{pointerEvents: "none"}}
      open={!!anchorEl && props.open}
      onClose={close}
      anchorEl={anchorEl}
      anchorOrigin={{vertical: "top", horizontal: "left"}}
      transformOrigin={{vertical: "top", horizontal: "right"}}
      MenuListProps={{
        onMouseEnter: () => setInMenu(true),
        onMouseLeave: () => setInMenu(false)
      }}
    >
      <div ref={(ref) => setItemsEl(ref)} style={{pointerEvents: "auto", maxHeight: "20rem", overflowY: "auto"}}>
        {props.children}
      </div>
    </Menu>
  </div>;
}
