/**
 * Copyright ©2022 Drivepoint
 */

import React from "react";
import {useStore} from "@d4lton/node-frontend";
import useStateChange from "@hooks/useStateChange";
import BainbridgeUser from "@services/user/BainbridgeUser";
import ExcelCompanyStore from "@services/store/ExcelCompanyStore";
import "./UserNameAndCompany.less";

export default function UserNameAndCompany(props: any): any {

  const company = useStateChange<any>("company");
  const [excelCompany] = useStore<any, ExcelCompanyStore>(ExcelCompanyStore);
  const user = useStateChange<BainbridgeUser>("user");

  function getFirstNameAndInitial(): any {
    if (user?.excelUser?.firstName) {
      return <div className="name-and-initial">
        {`${user?.excelUser?.firstName} ${user?.excelUser?.lastName[0]}.`}
      </div>;
    }
  }

  function getCompany(): any {
    if (company?.id && excelCompany?.name) {
      const classes = ["company-name"];
      if (company.id !== user?.excelUser?.excelCompanyId) { classes.push("impersonating"); }
      return <div className={classes.join(" ")}>{excelCompany.name}</div>;
    }
  }

  function getNameAndCompany(): any {
    if (user?.excelUser) {
      return <>
        {getFirstNameAndInitial()}
        {getCompany()}
      </>;
    }
  }

  return <div className="username-and-company">
    {getNameAndCompany()}
  </div>;
}
