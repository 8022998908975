/**
 * Copyright ©2023 Drivepoint
 */

import React, {MouseEvent, ReactNode} from "react";
import {Button, ButtonProps} from "@mui/material";
import Logger from "@utilities/logger/Logger";
import "./DPButton.less";

const logger = Logger.logger;

export type DPButtonProps = {
  label: ReactNode | "string";
  variant?: "text" | "contained" | "outlined";
  type?: "primary" | "secondary" | "success" | "danger";
  onClick?: (event: MouseEvent) => void;
  submit?: boolean;
} & Omit<ButtonProps, "variant" | "color" | "type" | "onClick">;

export default function DPButton({label, variant = "contained", type = "primary", onClick, submit, ...rest}: DPButtonProps): any {

  const classes = [
    "standard-button",
    `standard-button-type-${type}`,
    `standard-button-variant-${variant}`
  ];

  return <Button className={`${classes.join(" ")}`} variant={variant} onClick={onClick} type={submit ? "submit" : undefined} {...rest}>
    {label}
  </Button>;

}
