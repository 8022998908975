/**
 * Copyright ©2022 Drivepoint
 */

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import jwtDecode from "jwt-decode";
import Page from "../../Page";
import useQueryParams from "@hooks/useQueryParams";
import WebAppServerClient from "@services/clients/WebAppServerClient";
import Logger from "@utilities/logger/Logger";
import Firebase from "@services/firebase/Firebase";
import BainbridgeAuthentication from "../../quickstart/BainbridgeAuthentication/BainbridgeAuthentication";
import State from "@services/state/State";
import Logo from "@components/Logo/Logo";
import "./SignInPage.less";

const logger = Logger.logger;

export default function SignInPage(props: any): any {

  const queryParams = useQueryParams();
  const navigate = useNavigate();

  useEffect(() => {
    State.set("chrome", "disabled");
    Firebase
      .signOut()
      .then(async () => {
        const jwt = queryParams.get("token");
        if (jwt) {
          await clearPasswordReset(jwt);
        } else {
          await attemptAutoSignInWithEmailLink();
        }
      });
    return () => {
      State.set("chrome", "enabled");
    };
  }, []);

  async function clearPasswordReset(jwt: string): Promise<void> {
    try {
      const payload: any = jwtDecode(jwt);
      if (payload.is_reset) {
        await WebAppServerClient.clearPasswordReset({token: jwt});
      }
    } catch (error: any) {
      logger.error(error.message);
    }
  }

  async function attemptAutoSignInWithEmailLink(): Promise<void> {
    const email = localStorage.getItem("bb-auto-sign-in-email");
    localStorage.removeItem("bb-auto-sign-in-email");
    if (email && Firebase.isSignInWithEmailLink(window.location.href)) {
      const user = await Firebase.signInWithEmailLink(email);
      if (user) { return navigate("/"); }
    }
  }

  function signUp(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    navigate("/quickstart");
  }

  async function onSuccess(excelUser: any): Promise<void> {
    if (queryParams.has("cb")) {
      window.location.replace(`/?cb=${queryParams.get("cb")}`);
    } else {
      window.location.replace("/");
    }
  }

  async function onFailure(error: any): Promise<void> {
    logger.debug(error);
  }

  function renderLogin(): any {
    return <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
      <div style={{color: "#AAAAAA"}}>No account yet?</div>
      <Button style={{margin: "0 0.5em"}} onClick={signUp}>SIGN UP</Button>
    </div>;
  }

  return <Page>
    <div className="sign-in-page">
      <div className="header">
        <Logo />
        <div style={{flexGrow: "1"}}/>
        {renderLogin()}
      </div>
      <div className="phase">
        <div className="title">Sign In</div>
        <div className="bainbridge-authentication-container">
          <BainbridgeAuthentication onSuccess={onSuccess} onFailure={onFailure} mode="sign_in" />
        </div>
      </div>
    </div>
  </Page>;

}
