/**
 * Copyright ©2023 Drivepoint
 */

import React, {useImperativeHandle, useState} from "react";
import {Menu, MenuItem, MenuProps, SxProps} from "@mui/material";
import Template from "@utilities/template/Template";
import Logger from "@utilities/logger/Logger";
import "./DPMenu.less";

const logger = Logger.logger;

export type StandardMenuItem = {
  id: string;
  label: any;
  visible?: any;
  disabled?: boolean;
  iconComponent?: any;
  sxOverride?: SxProps;
};

export type StandardMenuProps = {
  items: StandardMenuItem[];
  onClick: (item: StandardMenuItem, data?: any) => void;
} & Pick<MenuProps, "anchorOrigin" | "transformOrigin">;

export type StandardMenuInterface = {
  open: (element: HTMLElement, data?: any) => void;
  close: () => void;
};

const DPMenu = React.forwardRef<StandardMenuInterface, StandardMenuProps>((props: StandardMenuProps, ref: any): any => {

  useImperativeHandle(ref, (): StandardMenuInterface => ({
    open: (element: HTMLElement, data?: any): void => {
      setItems(getItems(data));
      setData(data);
      setAnchor(element);
    },
    close: () => close()
  }));

  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [data, setData] = useState<any>();
  const [items, setItems] = useState<StandardMenuItem[]>([]);

  function getItems(data?: any): StandardMenuItem[] {
    data = data == null ? {} : data;
    const items = props.items.filter(item => {
      if (typeof item.visible === "string") { return Template.parse(item.visible, {data}) === "true"; }
      if (typeof item.visible === "boolean") { return item.visible; }
      return true;
    });
    if (!items.length) {
      items.push({id: "no_items", label: "No Options Available", disabled: true});
    }
    return items;
  }

  function close(): void {
    setAnchor(null);
    setItems([]);
    setData(null);
  }

  function onMenuItemClick(item: StandardMenuItem): void {
    close();
    props.onClick(item, data);
  }

  function renderItemLabel(item: StandardMenuItem): any {
    if (typeof item.label === "string") { return Template.parse(item.label, {data}); }
    return item.label;
  }

  function renderMenuItem(item: StandardMenuItem): any {
    return <MenuItem key={`menu_item_${item.id}`} onClick={() => onMenuItemClick(item)} disableRipple disabled={item.disabled} sx={item.sxOverride}>
      {item?.iconComponent}
      {item?.iconComponent ? <div>&nbsp;&nbsp;</div> : ""}
      {renderItemLabel(item)}
    </MenuItem>;
  }

  function preventContextMenu(element: HTMLDivElement | null): void {
    element?.addEventListener("contextmenu", (event: any) => {
      event.preventDefault();
      if (event.target.classList.contains("MuiBackdrop-root")) { close(); }
    });
  }

  return <Menu
    className="dp-menu"
    open={!!anchor}
    onClose={close}
    anchorEl={anchor}
    anchorOrigin={props.anchorOrigin}
    transformOrigin={props.transformOrigin}
    ref={ref => preventContextMenu(ref)}
  >
    {items.map(item => renderMenuItem(item))}
  </Menu>;

});

export default DPMenu;
