/**
 * Copyright ©2022 Dana Basken
 */

export const LoggerLevel: any = {
  "FATAL": 0,
  "ERROR": 10,
  "WARN": 20,
  "INFO": 30,
  "DEBUG": 40,
  "TRACE": 50,
  "ALL": 60
} as const;

export type LoggerLevel = typeof LoggerLevel[keyof typeof LoggerLevel];

export const LoggerLevelName = (type: LoggerLevel) => Object.keys(LoggerLevel)[type];
export const LoggerLevelFromName = (name: keyof typeof LoggerLevel) => LoggerLevel[name];
export const LoggerLevelFromString = (name: string) => LoggerLevel[name];
