/**
 * Copyright ©2023 Drivepoint
 */

import React, {createRef, useEffect, useRef, useState} from "react";
import {EmailAuthProvider, GoogleAuthProvider, User} from "firebase/auth";
import firebase from "firebase/compat/app";
import AdditionalUserInfo = firebase.auth.AdditionalUserInfo;
import jwtDecode from "jwt-decode";
import Logger from "@utilities/logger/Logger";
import ErrorDialog, {ErrorDialogInterface} from "../../../widgets/component/ErrorDialog";
import Firebase from "@services/firebase/Firebase";
import WebAppServerClient from "@services/clients/WebAppServerClient";
import email from "../../../../assets/images/email.svg";
import {Config} from "@bainbridge-growth/common-ts";
import "firebaseui/dist/firebaseui.css";
import "./BainbridgeAuthentication.less";

const logger = Logger.logger;

export type BainbridgeAuthenticationProps = {
  mode: "sign_up" | "sign_in";
  companyId?: string;
  state?: any;
  invitation?: any;
  onSuccess: (excelUser: any) => void;
  onFailure: (error: any) => void;
};

export default function BainbridgeAuthentication(props: BainbridgeAuthenticationProps): any {

  const errorDialog = useRef<ErrorDialogInterface>();
  const state = useRef<any>();
  const invitation = useRef<any>();

  const [container, setContainer] = useState<any>();

  useEffect(() => {
    if (props.mode === "sign_in") { Firebase.autoSetUpUser = false; }
    return () => {
      if (props.mode === "sign_in") { Firebase.autoSetUpUser = true; }
    };
  }, []);

  useEffect(() => {
    if (props.state) {
      state.current = jwtDecode(props.state);
    }
    invitation.current = props.invitation;
  }, [props]);

  useEffect(() => {
    if (!container) { return; }
    const fullLabelPrefix = props.mode === "sign_in" ? "Sign in with " : "Sign up with ";
    Firebase.ui.start(container, {
      signInOptions: [
        {provider: GoogleAuthProvider.PROVIDER_ID, customParameters: {prompt: "select_account"}, fullLabel: `${fullLabelPrefix}Google`},
        {provider: EmailAuthProvider.PROVIDER_ID, requireDisplayName: true, buttonColor: "white", fullLabel: `${fullLabelPrefix}Email`, iconUrl: email}
      ],
      signInFlow: "popup",
      tosUrl: "https://www.drivepoint.io/terms/terms-of-service",
      privacyPolicyUrl: "https://www.drivepoint.io/terms/privacy-policy",
      callbacks: {
        signInSuccessWithAuthResult: onFirebaseSignInSuccess
      }
    });
  }, [container]);

  function onFirebaseSignInSuccess(result: any): boolean {
    const user: User = result.user;
    const additionalUserInfo: AdditionalUserInfo = result.additionalUserInfo;
    switch (props.mode) {
      case "sign_in":
        handleSignInSuccess(user, additionalUserInfo);
        break;
      case "sign_up":
        handleSignUpSuccess(user, additionalUserInfo);
        break;
    }
    return false;
  }

  async function handleSignInSuccess(user: User, additionalUserInfo: AdditionalUserInfo): Promise<void> {
    props.onSuccess(user);
  }

  async function handleSignUpSuccess(user: User, additionalUserInfo: AdditionalUserInfo): Promise<void> {
    try {
      const excelUser = await createExcelUser(user);
      props.onSuccess(excelUser);
    } catch (error: any) {
      logger.error(error.message);
      showError(error);
    }
  }

  async function createExcelUser(user: User): Promise<any> {
    Firebase.token = await user.getIdToken();
    const companyId = props.companyId || invitation.current?.company?.id || "";
    const [firstName, ...lastName] = (user?.displayName?.split(" ") || []).filter((it: any) => it);
    const payload: any = {...user, firstName, lastName: lastName.join(" "), excelCompanyId: companyId, createState: state.current};
    const excelUser = await WebAppServerClient.request("/ui/excelUser/quickstart", "POST", payload);
    await Firebase.reload();
    return excelUser;
  }

  function showError(error: any): void {
    logger.error(error.message);
    let message = error.message || "An unknown error occurred while attempting to create your account.";
    if (error.code === 409) { // Duplicate Firebase user
      message = "An account already exists with this email.";
    }
    const body = <div style={{display: "flex", flexDirection: "column", gap: "0.5em"}}>
      <div>{message}</div>
      <div>Please contact <a href={Config.get("urls.customer_support")}>Drivepoint Support</a> for assistance.</div>
    </div>;
    errorDialog?.current?.show(body, () => {
      Firebase.signOut().then(() => window.location.reload());
    });
  }

  return <div className="bainbridge-authentication">
    <ErrorDialog ref={errorDialog} />
    <div className="sign-in-container" ref={ref => setContainer(ref)} />
  </div>;

}
