/**
 * Copyright ©2022 Drivepoint
 */

import {Config} from "@bainbridge-growth/common-ts";
import ITelemetryProvider from "./ITelemetryProvider";

/**
 * Segment-specific functionality.
 */

declare global {
  interface Window {Appcues: any}
}

export default class Appcues implements ITelemetryProvider {

  get name(): string { return "appcues"; }

  async embed(): Promise<void> {
    const script = document.createElement("script");
    script.src = `//fast.appcues.com/${Config.get("appcues.id")}.js`;
    document.head.append(script);
  }

  async identify(data: any): Promise<void> {
    if (!window.Appcues) { return; }
    if (data) {
      window.Appcues.identify(data.email, {firstName: data.excelUser?.firstName, companyName: data.excelUser?.excelCompanyId, email: data.email});
    }
  }

  async login(data: any): Promise<void> {
  }

  async logout(data: any): Promise<void> {
  }

  async pageView(data: any): Promise<void> {
    if (!window.Appcues) { return; }
    window.Appcues.page();
  }

  async track(event: string, data: any = {}): Promise<void> {
  }

}
