/**
 * Copyright ©2022 Drivepoint
 */

import React, {createRef, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {List} from "@mui/material";
import useRoutes from "@hooks/useRoutes";
import Telemetry from "@services/telemetry/Telemetry";
import ServiceRegistry from "@services/ServiceRegistry";
import YesNoDialog, {YesNoDialogInterface} from "@components/YesNoDialog/YesNoDialog";
import Logger from "@utilities/logger/Logger";
import useStateChange from "@hooks/useStateChange";
import BainbridgeUser from "@services/user/BainbridgeUser";
import NavigationSectionItem from "./NavigationSectionItem/NavigationSectionItem";
import NavigationPageItemMenu, {NavigationPageItemMenuInterface} from "@components/NavigationPageItemMenu/NavigationPageItemMenu";
import PageEditDialog from "../../../widgets/component/PageEditDialog";
import "./Navigation.less";

const logger = Logger.logger;

type ContextMenuProps = {
  x: number;
  y: number;
  item: any;
};

export type NavigationProps = {
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
};

export default function Navigation(props: NavigationProps): any {

  const yesNoDialog = useRef<YesNoDialogInterface>();
  const pageEditDialog = useRef<any>();
  const menu = createRef<NavigationPageItemMenuInterface>();
  const location = useLocation();
  const navigate = useNavigate();
  const routes = useRoutes(true);
  const user = useStateChange<BainbridgeUser>("user");

  const [navigationStates, setNavigationStates] = useState<any>();
  const [currentPage, setCurrentPage] = useState<any>();
  const [sectionContextMenu, setSectionContextMenu] = useState<ContextMenuProps | null>(null);
  const [pageContextMenu, setPageContextMenu] = useState<ContextMenuProps | null>(null);

  useEffect(() => {
    setCurrentPage(ServiceRegistry.routeService.getPageByPathname(location.pathname));
  }, [location, routes]);

  useEffect(() => {
    Telemetry.track("page_change", {page_id: currentPage?.id, path: currentPage?.path, name: currentPage?.name, urlKey: currentPage?.urlKey});
  }, [currentPage]);

  function getTokenValue(token: string): string {
    switch (token) {
      case ":company": return ServiceRegistry.companyService.company?.id || "-";
    }
    return "-";
  }

  function replaceTokens(path: string): string {
    const tokens = path.match(new RegExp(/(:\w+)/g));
    if (tokens) {
      for (const token of tokens) {
        path = path.replace(token, getTokenValue(token));
      }
    }
    return path;
  }

  function getExternalUrl(page: any): string {
    return page.urlKey ? ServiceRegistry.companyService.company?.[page.urlKey] : page.url;
  }

  function openExternalPage(page: any): void {
    if (user || page.public) {
      const url = getExternalUrl(page);
      if (url) {
        window.open(url, "_blank");
      } else if (page.path) {
        navigate(replaceTokens(page.path));
      }
    }
  }

  function onPageClick(event: any, page: any): void {
    event.preventDefault();
    event.stopPropagation();
    Telemetry.track("user_navigate", page);
    if (page.external) {
      openExternalPage(page);
    } else if (page.path) {
      navigate(replaceTokens(page.path));
    }
    if (props.onClose) { props.onClose(); }
  }

  function getNavigationStates(): any {
    if (navigationStates) { return JSON.parse(navigationStates); }
    if (localStorage.navigationStates) { return JSON.parse(localStorage.navigationStates); }
    return {};
  }

  function setSectionState(section: any, open: boolean): void {
    const states = getNavigationStates();
    states[section.name] = open;
    localStorage.navigationStates = JSON.stringify(states);
    setNavigationStates(localStorage.navigationStates);
  }

  function isSectionOpen(section: any): boolean {
    if (!props.open) { return false; }
    const states = getNavigationStates();
    return states[section.name] ?? true;
  }

  function toggleSection(section: any): void {
    if (props.open) {
      setSectionState(section, !isSectionOpen(section));
    } else {
      if (props.onOpen) { props.onOpen(); }
    }
  }

  function onPageContextMenu(event: any, page: any): void {
    event.preventDefault();
    menu.current?.open((event as any).target, page);
  }

  function onSectionContextMenu(event: any, section: any): void {
    event.preventDefault();
    event.stopPropagation();
    if (user?.excelUser?.role !== "superAdmin") { return; }
    setSectionContextMenu(sectionContextMenu === null ? {item: section, x: event.clientX - 2, y: event.clientY - 4} : null);
  }

  function editPage(page: any): void {
    pageEditDialog.current?.edit(page);
  }

  function onPageEdit(page?: any): void {
    editPage(ServiceRegistry.routeService.getPageDefinitionById(page.id));
  }

  function renderSectionItem(section: any): any {
    if (section.hidden) { return; }
    return <NavigationSectionItem
      key={`section_${section.id}`}
      item={section}
      open={props.open}
      onSectionContextMenu={onSectionContextMenu}
      onPageContextMenu={onPageContextMenu}
      toggleSection={toggleSection}
      onPageClick={onPageClick}
    />;
  }

  function renderNavigationItems(): any {
    if (!routes.sections?.length || !routes.pages?.length) { return; }
    const topSections = routes.sections.filter(section => section.id !== "SECONDARY");
    const bottomSections = routes.sections.filter(section => section.id === "SECONDARY");
    return <List className="navigation-root-list">
      {topSections.map(section => renderSectionItem(section))}
      <div className="navigation-section-spacer" />
      {bottomSections.map(section => renderSectionItem(section))}
    </List>;
  }

  return <div className="navigation">
    <YesNoDialog ref={yesNoDialog} title="Confirm Delete" yes="DELETE" no="Cancel" />
    <PageEditDialog ref={pageEditDialog} />
    <NavigationPageItemMenu ref={menu} onPageEdit={onPageEdit} inNav />
    {renderNavigationItems()}
  </div>;

}
