/**
 * Copyright ©2023 Drivepoint
 */

import dayjs from "dayjs";
import Logger from "../logger/Logger";
import TemplateSQLLibrary from "./libraries/TemplateSQLLibrary";
import ServiceRegistry from "@services/ServiceRegistry";

const logger = Logger.logger;

export default class Template {

  static AsyncFunction = async function() {}.constructor;

  static parse(template: string, data?: any, options: any = {}): any {
    if (data != null && typeof data === "string") { throw new Error("data must be object"); }
    const globals: any = {window: {}, document: {}, globalThis: {}, globals: options.globals ?? {}, features: ServiceRegistry.featureFlagService.featureFlags, ...data};
    let libraries: any[] = [{name: "dayjs", fn: dayjs}];
    if (Array.isArray(options.additionalLibraries)) { libraries = [...libraries, ...options.additionalLibraries]; }
    const body: string[] = [
      ...Object.keys(globals).map(it => `const ${it}=${JSON.stringify(globals[it])};`),
      ...libraries.map((library: any, index: number) => `const ${library.name}=arguments[${index}];`),
      "return `" + template + "`;"
    ];
    try {
      return Function(body.join(";"))(...libraries.map(it => it.fn));
    } catch (error: any) {
      if (options.debug) { throw error; }
      return template;
    }
  }

  static parseSQL(template: string, data?: any, globals?: any): any {
    return Template.parse(template, data, {additionalLibraries: TemplateSQLLibrary.functions(data), globals: globals ?? {}});
  }

  static run(code: string, data?: any, options: any = {}): any {
    const globals: any = {window: {}, document: {}, globalThis: {}, ...data};
    let libraries: any[] = [{name: "dayjs", fn: dayjs}];
    if (Array.isArray(options.additionalLibraries)) { libraries = [...libraries, ...options.additionalLibraries]; }
    const body: string[] = [
      ...Object.keys(globals).map(it => `const ${it}=${JSON.stringify(globals[it])};`),
      ...libraries.map((library: any, index: number) => `const ${library.name}=arguments[${index}];`),
      code
    ];
    return Function(body.join(";"))(...libraries.map(it => it.fn));
  }

  static async runAsync(code: string, data?: any, options: any = {}): Promise<any> {
    const globals: any = {window: {}, document: {}, globalThis: {}, globals: {}, ...data};
    let globalValues: any = options.globals ?? {};
    let libraries: any[] = [{name: "dayjs", fn: dayjs}];
    if (Array.isArray(options.additionalLibraries)) { libraries = [...libraries, ...options.additionalLibraries]; }
    libraries.push({name: "__set_globals__", fn: (globals: any) => globalValues = globals});
    const body: string[] = [
      ...Object.keys(globals).map(it => `let ${it}=${JSON.stringify(globals[it])};`),
      ...libraries.map((library: any, index: number) => `const ${library.name}=arguments[${index}];`),
      "__set_globals__(globals)",
      code,
      "return data;"
    ];
    const results = await Template.AsyncFunction(body.join(";"))(...libraries.map(it => it.fn));
    return {results, globals: globalValues};
  }

}
