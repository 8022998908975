/**
 * Copyright ©2022 Drivepoint
 */

import {Config} from "@bainbridge-growth/common-ts";
import ITelemetryProvider from "./ITelemetryProvider";
import Segment from "./Segment";
import Appcues from "./Appcues";
import FullStory from "./FullStory";
import ServiceRegistry from "../ServiceRegistry";

/**
 * This is where we embed third-party site analysis products. We do this here to keep index.html less cluttered, and
 * allow for conditional loading of a product if needed.
 */

export default class Telemetry {

  static providers: ITelemetryProvider[] = [
    new Segment(),
    new Appcues(),
    new FullStory()
  ];

  static async init() {
    for (const provider of Telemetry.providers) {
      if (Config.get(`${provider.name}.enabled`)) { await provider.embed(); }
    }
  }

  static async identify(data: any) {
    for (const provider of Telemetry.providers) {
      if (Config.get(`${provider.name}.enabled`)) { provider.identify(data); }
    }
  }

  static async login(data: any) {
    for (const provider of Telemetry.providers) {
      if (Config.get(`${provider.name}.enabled`)) { provider.login(data); }
    }
  }

  static async logout(data: any) {
    for (const provider of Telemetry.providers) {
      if (Config.get(`${provider.name}.enabled`)) { provider.logout(data); }
    }
  }

  static async pageView(user: any, data: any) {
    const page = ServiceRegistry.routeService.getPageByPathname(data?.pathname);
    const view = {user: user, page: page, data: data};
    for (const provider of Telemetry.providers) {
      if (Config.get(`${provider.name}.enabled`)) { provider.pageView(view); }
    }
  }

  static async track(event: string, data: any = {}): Promise<void> {
    for (const provider of Telemetry.providers) {
      if (Config.get(`${provider.name}.enabled`)) { provider.track(event, data); }
    }
  }

}
