/**
 * Copyright ©2022 Drivepoint
 */

import ITelemetryProvider from "./ITelemetryProvider";
import * as FS from "@fullstory/browser";
import {Config} from "@bainbridge-growth/common-ts";

export default class FullStory implements ITelemetryProvider {

  constructor() {
    if (Config.get("fullstory.enabled")) {
      FS.init({orgId: Config.get("fullstory.id")});
    }
  }

  async embed(): Promise<void> {
  }

  async identify(data: any): Promise<void> {
    if (data?.excelUser) {
      const uid = `${data.uid}-${Config.get("environment")}`;
      const payload = {
        email: data.email,
        environment_str: Config.get("environment"),
        version_str: Config.get("version"),
        hash_str: Config.get("hash"),
        displayName: `${data.excelUser.firstName} ${data.excelUser.lastName}`,
        company_str: data.excelUser.excelCompanyId
      };
      FS.identify(uid, payload);
    } else {
      FS.anonymize();
    }
  }

  async login(data: any): Promise<void> {
  }

  async logout(data: any): Promise<void> {
  }

  get name(): string { return "fullstory"; }

  async pageView(data: any): Promise<void> {
  }

  async track(event: string, data: any = {}): Promise<void> {
  }

}
