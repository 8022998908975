/**
 * Copyright ©2022 Drivepoint
 */

import {useEffect, useState} from "react";
import State from "../services/state/State";
import {EventBus} from "../services/eventbus/EventBus";
import Logger from "../utilities/logger/Logger";

const logger = Logger.logger;

export default function useStateChange<T>(key: string): T | undefined {

  const [value, setValue] = useState<T>(State.get(key));

  useEffect(() => {
    const registration = State.register(key, (event: any) => setValue(event.value));
    return () => {
      EventBus.unregister(registration);
    };
  }, [key]);

  return value;

}
