/**
 * Copyright ©2022 Drivepoint
 */

import Logger from "./logger/Logger";

const logger = Logger.logger;

export class English {

  /**
   * Get milliseconds from a simple english string that consists of an optional sign (+/-), a decimal value, and a
   * timeframe indicator of s, m, h, d, w, M for seconds, minutes, hours, days, weeks, months. Example: "2w" for 2 weeks.
   */
  static ms(time: string): number {
    const matches = time.match(/([+-]*)(\d+)([smhdwM])/);
    if (!matches) { throw new Error(`time string of '${time}' is not understood`); }
    const sign: string = matches[1] || "+";
    const timeframeCount: number = parseFloat(matches[2]);
    const timeframe: string = matches[3];
    const timeframeMs: number = English.getTimeframeMs(timeframe);
    const totalMs: number = timeframeMs * timeframeCount;
    return sign === "+" ? totalMs : -totalMs;
  }

  /**
   * Get milliseconds from a simple english string and then increase or reduce that amount by a "fuzz" percentage.
   * The percent is a fraction, so 0.25 represents 25%. See EnglishMs.ms().
   */
  static fuzzedMs(time: string, percent: number): number {
    const baseMs = English.ms(time);
    const fuzzMs = baseMs * percent;
    return baseMs + Math.ceil((Math.random() * fuzzMs) - (fuzzMs / 2.0));
  }

  /**
   * Get the number of milliseconds for a simple english string's timeframe. See EnglishMs.ms().
   */
  static getTimeframeMs(timeframe: string): number {
    switch (timeframe) {
      case "s": return 1000;
      case "m": return 60000;
      case "h": return 3600000;
      case "d": return 86400000;
      case "w": return 604800000;
      case "M": return 2592000000;
      default: throw new Error(`unknown timeframe: '${timeframe}'`);
    }
  }

  static numberFormatter(value: number, options?: Intl.NumberFormatOptions): string {
    if (value == null) { return ""; }
    const defaultOptions:Intl.NumberFormatOptions = {
      style: "currency",
      currency: "USD",
      notation: "compact",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    };
    return new Intl.NumberFormat("en-US", options ?? defaultOptions).format(value);
  };

  static numberToString(value: number): string {
    if (value === 0) { return "0"; }
    const kSize = 1000;
    let power = Math.floor(Math.log(value) / Math.log(kSize));
    value = Math.round(value / Math.pow(kSize, power));
    return `${value}${["", "K", "M", "G", "T"][power]}`;
  }

  static baseProperNoun(noun: string): string {
    const match = noun?.match(/^the (.+?)$/i);
    return match ? match[1] : noun;
  }

  static toCamelCase(text: string): string {
    return text
      .split(/([^a-zA-Z]+)/g)
      .reduce((parts: string[], part: string) => {
        if (part.trim() === "-") { part = ": "; }
        part = part.replace(/([A-Z])([A-Z]+)/, (substring: string, ...args: any[]) => `${args[0]}${args[1].toLowerCase()}`);
        return [...parts, part];
      }, [])
      .join("");
  }

  static pluralRules(value: number): string {
    const suffixes: any = {"one": "st", "two": "nd", few: "rd", other: "th"};
    const rules = new Intl.PluralRules("en", {type: "ordinal"});
    return `${value}${suffixes[rules.select(value)]}`;
  }

}
