/**
 * Copyright ©2022 Drivepoint
 */

import {styled, Typography, TypographyProps} from "@mui/material";
import {typography} from "../../configuration/theme";

const ThemedFormText = styled(Typography)<TypographyProps>(({theme}: {theme: any}) => {
  return {
    fontFamily: typography.fontFamily,
    color: theme.palette.content.primary.color
  };
});

export default ThemedFormText;
