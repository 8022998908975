/**
 * Copyright ©2022 Drivepoint
 */

import {useMemo} from "react";
import ServiceRegistry from "../services/ServiceRegistry";
import Logger from "../utilities/logger/Logger";
import useRoutes from "./useRoutes";

const logger = Logger.logger;

export type PageProps = {
  current: any
};

export default function usePage(): PageProps {

  const routes = useRoutes();

  return useMemo<PageProps>(() => {
    return {
      current: ServiceRegistry.routeService.getPageByPathname(location.pathname)
    };
  }, [location.pathname, routes]);

}
