/**
 * Copyright ©2022 Drivepoint
 */

import React, {JSX, ReactNode} from "react";
import PageContent from "../widgets/styled/PageContent";
import PageContainer from "../widgets/styled/PageContainer";

interface PageProps {
  children: ReactNode;
  toolbar?: ReactNode;
  className?: string
}

export default function Page(props: PageProps): JSX.Element {

  return <PageContainer className="page-container">
    {props.toolbar}
    <PageContent className={`page-content ${props.className || ""}`}>{props.children}</PageContent>
  </PageContainer>;

}
