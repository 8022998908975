/**
 * Copyright ©2022 Drivepoint
 */

import React, {useEffect, useRef, useState} from "react";
import {LinearProgress} from "@mui/material";
import {Config} from "@bainbridge-growth/common-ts";
import Page from "../Page";
import PageContainer from "../PageContainer";
import {EventBus} from "@services/eventbus/EventBus";
import Logger from "@utilities/logger/Logger";
import ErrorDialog, {ErrorDialogInterface} from "../../widgets/component/ErrorDialog";
import Firebase from "@services/firebase/Firebase";
import "./LoadingPage.less";

const logger = Logger.logger;

export default function LoadingPage(props: any): any {

  const errorDialog = useRef<ErrorDialogInterface>();

  const [retrying, setRetrying] = useState<boolean>();

  useEffect(() => {
    const registrations = EventBus.registerMany("load_bainbridge_user:retry", "load_bainbridge_user:error", event => {
      switch (event.type) {
        case "load_bainbridge_user:retry":
          setRetrying(true);
          break;
        case "load_bainbridge_user:error":
          setRetrying(false);
          const body = <div className="loading-page-body">
            <div className="loading-page-issue">
              We apologize, but we were unable to connect the the Drivepoint service, please <a href="src/pages#" onClick={contactSupport}>contact support</a> if the situation persists.
            </div>
            <div className="loading-page-sign-out">
              Click <a href="#" onClick={signOut}>here</a> to sign out if you would like to try another account.
            </div>
          </div>;
          errorDialog?.current?.show(body, () => {
            window.location.reload();
          });
          break;
      }
      logger.debug(event);
    });
    return () => {
      EventBus.unregister(...registrations);
    };
  }, []);

  async function signOut(event: any): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    await Firebase.signOut();
  }

  function contactSupport(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    window.open(Config.get("urls.customer_support"), "_blank");
  }

  function renderProgress(): any {
    if (!retrying) { return; }
    return <div className="loading-page-progress">
      <LinearProgress variant="indeterminate" />
    </div>;
  }

  return <Page>
    <PageContainer>
      <ErrorDialog ref={errorDialog} close="RETRY" />
      <div className="loading-page">
        <div>loading...</div>
        {renderProgress()}
      </div>
    </PageContainer>
  </Page>;

}
