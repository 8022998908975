/**
 * Copyright ©2022 Drivepoint
 */

import {useEffect, useState} from "react";
import {EventBus} from "@services/eventbus/EventBus";
import Logger from "../utilities/logger/Logger";
import {FirebaseAuthenticationState} from "@services/firebase/Firebase";
import State from "../services/state/State";

const logger = Logger.logger;

export default function useReady(): any {

  const [ready, setReady] = useState<boolean>();

  useEffect(() => {
    const registrations = State.registerMany("authentication", "flags", "routes", "excel_company", (event: any) => {
      let allReady = true;
      switch (event.key) {
        case "authentication":
          if (event.value === FirebaseAuthenticationState.INITIALIZING) { allReady = false; }
          break;
        case "flags":
          if (!event.value) { allReady = false; }
          break;
        case "routes":
          if (!event.value?.pages?.length) { allReady = false; }
          break;
        case "excel_company":
          if (!event.value) { allReady = false; }
          break;
      }
      setReady(allReady);
    });
    return () => {
      EventBus.unregister(...registrations);
    };
  }, []);

  return ready;

}
