/**
 * Copyright ©2022 Drivepoint
 */

import React, {forwardRef, useImperativeHandle, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import ServiceRegistry from "@services/ServiceRegistry";
import useStateChange from "@hooks/useStateChange";
import {BreadcrumbItem} from "@services/BreadcrumbManager";
import Logger from "@utilities/logger/Logger";
import "./Breadcrumbs.less";

const logger = Logger.logger;

export type BreadcrumbBarProps = {
};

export type BreadcrumbBarInterface = {
  setPageLabel: (id: string, label: string) => void;
};

const Breadcrumbs = forwardRef<BreadcrumbBarInterface, BreadcrumbBarProps>((props, ref): any => {

  useImperativeHandle(ref, (): BreadcrumbBarInterface => ({
    setPageLabel: (id: string, label: string): void => setPageLabel(id, label)
  }));

  const navigate = useNavigate();
  const location = useLocation();
  const company = useStateChange<any>("company");

  const page = ServiceRegistry.routeService.getPageByPathname(location.pathname);

  const [pageLabels, setPageLabels] = useState<any>({});

  function setPageLabel(id: string, label: string): void {
    setPageLabels({...pageLabels, [id]: label});
  }

  function onBreadcrumbClick(item: BreadcrumbItem): void {
    const page = item.pageId === "" ? ServiceRegistry.routeService.defaultPage : ServiceRegistry.routeService.getPageById(item.pageId);
    if (!page) { return; }
    const path = ServiceRegistry.routeService.getPathForContext(page.path, company);
    navigate(path);
  }

  function renderBreadcrumb(item: BreadcrumbItem, index: number, current: boolean): any {
    const label = pageLabels?.[item.id] ?? item.label;
    return <div key={`crumb_${index}`} className="breadcrumbs-breadcrumb">
      {!!index && <div className="breadcrumbs-breadcrumb-separator">/</div>}
      <Button className="breadcrumbs-breadcrumb-button" disabled={current} disableRipple onClick={() => onBreadcrumbClick(item)}>{label}</Button>
    </div>;
  }

  function walkParentPages(page: any): any[] {
    if (!page?.parent) { return [page].filter(it => it); }
    return [
      page,
      walkParentPages(ServiceRegistry.routeService.getPageById(page.parent))
    ]
      .filter(it => it)
      .flat();
  }

  function renderBreadcrumbs(): any {
    const pages = walkParentPages(page);
    if (company?.name) { pages.push({id: "", name: company.name, pageId: ""}); }
    const crumbs: BreadcrumbItem[] = pages.reverse().map(page => ({id: page.id, label: page.name, pageId: page.id}));
    return <div className="breadcrumbs-breadcrumbs">
      {crumbs.map((item: BreadcrumbItem, index: number) => renderBreadcrumb(item, index, index === crumbs.length - 1))}
    </div>;
  }

  return <div className="breadcrumbs">
    {renderBreadcrumbs()}
  </div>;

});

export default Breadcrumbs;
