/**
 * Copyright ©2022 Drivepoint
 */

import {useEffect, useState} from "react";
import usePathContext from "./usePathContext";
import ServiceRegistry from "../services/ServiceRegistry";
import Logger from "../utilities/logger/Logger";
import useStateChange from "./useStateChange";
import BainbridgeUser from "../services/user/BainbridgeUser";

const logger = Logger.logger;

export type RoutesProps = {
  pages?: any[];
  sections?: any[];
};

export default function useRoutes(navigation: boolean = false): RoutesProps {

  const flags = useStateChange<any>("flags");
  const pathContext = usePathContext();
  const user = useStateChange<BainbridgeUser>("user");
  const company = useStateChange<any>("company");
  const routes = useStateChange<any>("routes");

  const [visiblePages, setVisiblePages] = useState<any[]>();
  const [visibleSections, setVisibleSections] = useState<any[]>();

  useEffect(() => {
    const pages = routes?.pages || ServiceRegistry.routeService.pages;
    const sections = routes?.sections || ServiceRegistry.routeService.sections;
    setVisiblePages(pages.filter((page: any) => isPageVisible(page)));
    setVisibleSections(sections.map((section: any) => getVisibleSection(section)).filter((it: any) => it));
  }, [routes, company?.id, user, flags]);

  /**
   * Check to see if a company's tier allows access to this page. A page can have an array of tiers, both
   * included and excluded tiers. An excluded tier is prefixed with an exclamation mark.
   */
  function companyCanAccessTier(page: any): boolean {
    if (!page.tiers?.length) { return true; } // no tiers, page is implicitly included
    if (!company) { return false; } // no company, no access
    // get any excluded tiers, and reject this page if company matches an excluded tier:
    const excludeTiers = page.tiers
      .filter((tier: string) => tier.startsWith("!"))
      .map((tier: string) => tier.replace(/^!/, ""));
    if (excludeTiers.includes(company.tier)) { return false; }
    // get any included tiers, and reject this page if there are tiers, but the company doesn't match one
    const includeTiers = page.tiers.filter((tier: string) => !tier.startsWith("!"));
    if (!includeTiers.length) { return true; } // no included tiers, page is implicitly included
    return includeTiers.includes(company.tier);
  }

  function isPageVisible(page: any): boolean {
    if (page.public) { return true; }
    // auth isn't ready, or user isn't fully loaded:
    if (!user) { return false; }
    // page is hidden from nav:
    if (navigation && page.hidden) { return false; }
    // check user role for access to page if needed:
    if (flags[`page.${page.id}.enabled`] === false) { return false; }
    if (!companyCanAccessTier(page)) { return false; }
    // if we're in the context of a company and a company-specific flag exists, use its value for enabled:
    if (pathContext.companyId) { // TODO: deprecate in favor of LaunchDarkly company rule
      const companyKey = `page.${pathContext.companyId}.${page.id}.enabled`;
      if (flags[companyKey] !== undefined) { return flags[companyKey]; }
    }
    // if we're not in a company-specific context, or there's no company-specific flag, try a global flag for page:
    const allCompanyKey = `page.ALL.${page.id}.enabled`; // TODO: deprecate the "ALL" flags in favor of LaunchDarkly rules
    return flags[allCompanyKey] !== undefined ? flags[allCompanyKey] : true;
  }

  function getVisibleSection(section: any): any {
    // auth isn't ready, or user isn't fully loaded:
    if (!user) { return undefined; }
    // section is hidden:
    if (section.hidden) { return undefined; }
    // find all visible pages for this section, if any:
    const pages = section.pages.filter((page: any) => isPageVisible(page));
    if (!pages.length) { return undefined; }
    // rebuild the section object with just the visible pages and return:
    return Object.assign({}, section, {pages: pages});
  }

  return {
    pages: visiblePages,
    sections: visibleSections
  };

};
