/**
 * Copyright ©2022 Drivepoint
 */

import React, {useEffect, useState} from "react";
import Page from "./Page";
import PageContainer from "./PageContainer";
import BrandLink from "../widgets/styled/BrandLink";
import {useNavigate} from "react-router-dom";
import ThemedFormText from "../widgets/styled/ThemedFormText";
import ServiceRegistry from "../services/ServiceRegistry";
import State from "../services/state/State";
import {FirebaseAuthenticationState} from "../services/firebase/Firebase";
import Logger from "../utilities/logger/Logger";

const logger = Logger.logger;

export default function NotFoundPage(props: any): any {

  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>();

  useEffect(() => {
    setShow(false);
    const timeout = setTimeout(() => { setShow(true); }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  function home(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    // reset the companyId if the user is logged in, so an invalid URL won't break the redirect with a bogus company
    if (State.get("authentication") === FirebaseAuthenticationState.AUTHENTICATED) {
      ServiceRegistry.companyService.setCompanyById(ServiceRegistry.companyService.defaultCompanyId);
    }
    navigate("/");
  }

  function render(): any {
    if (!show) { return; }
    return <ThemedFormText>
      We're sorry, that page could not be found. Click <BrandLink onClick={home}>here</BrandLink> to go to go to the home page.
    </ThemedFormText>;
  }

  return <Page>
    <PageContainer>
      {render()}
    </PageContainer>
  </Page>;

}
