/**
 * Copyright ©2022 Drivepoint
 */

import LaunchDarklyClient from "../clients/LaunchDarklyClient";
import State from "../state/State";
import Logger from "@utilities/logger/Logger";

const logger = Logger.logger;

export default class FeatureFlagsService extends EventTarget {

  private _featureFlags: any = {};

  constructor() {
    super();
    State.registerMany("user", "company", this.onUserStateChange.bind(this));
    LaunchDarklyClient.client.on("change", this.refreshFeatureFlags.bind(this));
  }

  async init(): Promise<void> {
    State.set("flags", this.featureFlags);
  }

  async onLaunchDarklyFlagChange(): Promise<void> {
    await this.refreshFeatureFlags();
  }

  async onUserStateChange(event: any): Promise<void> {
    await LaunchDarklyClient.identify(State.get("user"), State.get("company"));
    await this.refreshFeatureFlags();
  }

  async refreshFeatureFlags(): Promise<void> {
    this.featureFlags = await LaunchDarklyClient.flags();
  }

  get featureFlags(): any {
    return this._featureFlags;
  }

  set featureFlags(value: any) {
    this._featureFlags = value || {};
    State.set("flags", this._featureFlags);
  }

}
