/**
 * Copyright ©2022 Dana Basken
 */

import {FirebaseApp, initializeApp} from "firebase/app";
import {getFirestore, Firestore, getDoc, getDocs, setDoc, collection, doc, onSnapshot} from "firebase/firestore";
import {
  Auth, getAuth, User, UserCredential, Unsubscribe, verifyPasswordResetCode, confirmPasswordReset,
  signInWithEmailAndPassword, signInWithCustomToken, signOut, isSignInWithEmailLink, signInWithEmailLink, sendPasswordResetEmail
} from "firebase/auth";
import * as firebaseui from "firebaseui";
import {Config, Utilities} from "@bainbridge-growth/common-ts";
import Logger from "@utilities/logger/Logger";
import State from "../state/State";
import BainbridgeUser from "../user/BainbridgeUser";
import ServiceRegistry from "../ServiceRegistry";
import {EventBus} from "../eventbus/EventBus";
import FirebaseEvent from "./FirebaseEvent";

const logger = Logger.logger;

export enum FirebaseAuthenticationState {
  INITIALIZING = "initializing",
  AUTHENTICATED = "authenticated",
  UNAUTHENTICATED = "unauthenticated"
}

export default class Firebase {

  private static MAX_LOAD_USER_RETRIES = 5;

  private static _app: FirebaseApp;
  private static _auth: Auth;
  private static _ui?: firebaseui.auth.AuthUI;
  private static _firestore: Firestore;
  private static _auth_unsubscribe: Unsubscribe;
  private static _user: User | null;
  private static _token?: string;
  private static _role?: string;
  private static _claims?: any;
  private static _autoRefreshInterval?: any;
  private static _autoSetUpUser: boolean = true;

  static async init(): Promise<void> {
    State.set("authentication", FirebaseAuthenticationState.INITIALIZING);
    const options = Config.get("firebase");
    Firebase._app = initializeApp(options, `${options.projectId}.${Config.get("environment")}`);
    Firebase._auth = getAuth(Firebase._app);
    Firebase._firestore = getFirestore(Firebase._app);
    Firebase._auth_unsubscribe = Firebase._auth.onAuthStateChanged(Firebase.onAuthStateChanged);
    Firebase.autoRefreshToken();
  }

  static async stop(): Promise<void> {
    Firebase.reset();
    Firebase._auth_unsubscribe();
    clearInterval(Firebase._autoRefreshInterval);
  }

  static async onAuthStateChanged(user: User | null): Promise<void> {
    Firebase._user = user;
    if (user) {
      await Firebase.setUpUser(user);
    } else {
      Firebase.reset();
    }
  }

  static autoRefreshToken(): void {
    Firebase._autoRefreshInterval = setInterval(async () => {
      try {
        if (Firebase._user) {
          const result = await Firebase._user.getIdTokenResult();
          const now = Date.now() / 1000;
          const exp = parseFloat(result.claims.exp || `${now}`);
          const ttl = Math.floor(exp - now);
          logger.trace(`firebase token TTL: ${ttl}`);
          if (ttl < Config.get("token.refresh_seconds", 600)) {
            logger.debug("refreshing auth token");
            Firebase.token = await Firebase._user.getIdToken(true);
          }
        }
      } catch (error: any) {
        logger.error(error.message);
      }
    }, Config.get("token.check_seconds", 60000));
  }

  static async setUpUser(user: User) {
    if (!Firebase.autoSetUpUser) { return; }
    ServiceRegistry.serverSentEventService.disconnect();
    Firebase.token = await user?.getIdToken();
    const result = await user?.getIdTokenResult();
    Firebase._claims = result?.claims;
    Firebase._role = result?.claims?.role as string;
    await this.loadBainbridgeUser(user);
  }

  static async loadBainbridgeUser(user: User): Promise<void> {
    let retries = 0;
    while (true) {
      if (!Firebase.autoSetUpUser) { return; }
      try {
        const bainbridgeUser = new BainbridgeUser(user);
        await bainbridgeUser.load();
        State.set("user", bainbridgeUser);
        ServiceRegistry.serverSentEventService.connect();
        State.set("authentication", FirebaseAuthenticationState.AUTHENTICATED);
        return;
      } catch (error: any) {
        logger.error(error.message);
        if (retries >= Firebase.MAX_LOAD_USER_RETRIES) {
          EventBus.dispatch(new FirebaseEvent("load_bainbridge_user:error", {user: user}));
          throw new Error("Could not connect");
        }
        EventBus.dispatch(new FirebaseEvent("load_bainbridge_user:retry", {user: user, retries: retries}));
        await Utilities.sleep(5000);
        retries++;
      }
    }
  }

  static reset(): void {
    State.set("authentication", FirebaseAuthenticationState.UNAUTHENTICATED);
    State.set("user", undefined);
    ServiceRegistry.serverSentEventService.disconnect();
    Firebase.token = undefined;
    Firebase._claims = undefined;
    Firebase._role = undefined;
  }

  static get auth(): Auth {
    return Firebase._auth;
  }

  static get user(): User | null {
    return Firebase._user;
  }

  static get ui(): firebaseui.auth.AuthUI {
    if (!Firebase._ui) {
      Firebase._ui = new firebaseui.auth.AuthUI(Firebase.auth);
    }
    return Firebase._ui;
  }

  static get token(): string | undefined {
    return Firebase._token;
  }

  static set token(value: string | undefined) {
    Firebase._token = value;
    State.set("firebase_token", Firebase._token);
  }

  static get role(): string | undefined {
    return Firebase._role;
  }

  static get claims(): any {
    return Firebase._claims;
  }

  static get autoSetUpUser(): boolean {
    return Firebase._autoSetUpUser;
  }

  static set autoSetUpUser(value: boolean) {
    Firebase._autoSetUpUser = value;
  }

  static async signIn(email: string, password: string): Promise<UserCredential | undefined> {
    if (Firebase._auth) {
      return signInWithEmailAndPassword(Firebase._auth, email, password);
    }
  }

  static async signInWithCustomToken(token: string): Promise<UserCredential | undefined> {
    if (Firebase._auth) {
      return signInWithCustomToken(Firebase._auth, token);
    }
  }

  static async signOut(): Promise<void> {
    if (Firebase._auth) {
      return signOut(Firebase._auth);
    }
  }

  static async reload(): Promise<void> {
    if (Firebase.user) {
      return Firebase.setUpUser(Firebase.user);
    }
  }

  static isSignInWithEmailLink(link: string): boolean {
    return Firebase._auth ? isSignInWithEmailLink(Firebase._auth, link) : false;
  }

  static async signInWithEmailLink(email: string): Promise<UserCredential> {
    return signInWithEmailLink(Firebase._auth, email);
  }

  static async verifyPasswordResetCode(code: string): Promise<string> {
    return verifyPasswordResetCode(Firebase._auth, code);
  }

  static confirmPasswordReset(oobCode: string, newPassword: string): Promise<void> {
    return confirmPasswordReset(Firebase._auth, oobCode, newPassword);
  }

  static async handleResetPassword() {
    if (this._user && this._user.email) {
      await sendPasswordResetEmail(this._auth, this._user.email);
    }
  }

}
