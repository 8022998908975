/**
 * Copyright ©2022 Drivepoint
 */

import React, {JSX, useEffect, useState, MouseEvent, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {Drawer, Toolbar} from "@mui/material";
import {Menu, MenuOpen} from "@mui/icons-material";
import Logo from "@components/Logo/Logo";
import Navigation from "../Navigation/Navigation";
import DrawerToggleButton from "../../../widgets/styled/DrawerToggleButton";
import ServiceRegistry from "@services/ServiceRegistry";
import Suspended from "../../../widgets/component/Suspended";
import State from "@services/state/State";
import useStateChange from "@hooks/useStateChange";
import BainbridgeUser from "@services/user/BainbridgeUser";
import Logger from "@utilities/logger/Logger";
import "./NavigationDrawer.less";

const logger = Logger.logger;

export type NavigationDrawerProps = {
  open?: boolean;
  toggle?: () => void;
  resizable?: boolean;
  handleWidth?: number;
  defaultWidth?: number;
  minWidth?: number;
  maxWidth?: number;
};

export default function NavigationDrawer(props: NavigationDrawerProps): JSX.Element {

  const content = useRef<any>();

  const company = useStateChange<any>("company");
  const user = useStateChange<BainbridgeUser>("user");
  const chrome = useStateChange<string>("chrome");
  const navigate = useNavigate();

  const [hideChrome, setHideChrome] = useState<boolean>(State.get("chrome") === "disabled");
  const [initialized, setInitialized] = useState<boolean>(!!State.get("user"));
  const [width, setWidth] = useState<number>(getWidth());

  useEffect(() => {
    setInitialized(!!user);
    setHideChrome(!user || chrome === "disabled");
  }, [user, chrome, company]);

  useEffect(() => {
    saveWidth();
  }, [width]);

  function saveWidth(): void {
    if (width == null) { return; }
    localStorage.setItem("drivepoint_nav_width", `${width}`);
  }

  function getWidth(): number {
    const width = localStorage.getItem("drivepoint_nav_width");
    if (width) { return parseInt(width); }
    return props.defaultWidth ?? 320;
  }

  function navigateToDefault(event: any): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let path = "/";
    if (user?.excelUser) {
      const page = ServiceRegistry.routeService.defaultPage;
      if (page) {
        path = ServiceRegistry.routeService.getPathForContext(page.path, company);
      }
    }
    navigate(path);
  }

  function onOpen(): void {
    if (props.toggle) { props.toggle(); }
  }

  function onHandleMouseDown(event: MouseEvent): void {
    if (!props.resizable) { return; }
    if (content.current) { content.current.classList.add("resizing"); }
    document.addEventListener("mousemove", onHandleMouseMove);
    document.addEventListener("mouseup", onHandleMouseUp);
  }

  function onHandleMouseMove(event: any): void {
    if (!event.buttons) { return onHandleMouseUp(event); } // letting go over an iframe seems to prevent normal onmouseup
    if (!content.current) { return; }
    if (!content.current.classList.contains("resizing")) { return; }
    let newWidth = event.clientX - document.body.offsetLeft;
    const minWidth = props.minWidth ?? 200;
    const maxWidth = props.maxWidth ?? 400;
    if (newWidth < minWidth) { newWidth = minWidth; }
    if (newWidth > maxWidth) { newWidth = maxWidth; }
    setWidth(newWidth);
  }

  function onHandleMouseUp(event: any): void {
    if (content.current) { content.current.classList.remove("resizing"); }
    document.removeEventListener("mousemove", onHandleMouseMove);
    document.removeEventListener("mouseup", onHandleMouseUp);
  }

  function onHandleDoubleClick(event: any): void {
    setWidth(props.defaultWidth ?? 320);
  }

  function renderHandle(): any {
    if (!props.resizable || !props.open) { return; }
    return <div
      className="navigation-drawer-handle"
      onMouseDown={onHandleMouseDown}
      onDoubleClick={onHandleDoubleClick}
      style={{width: props.handleWidth ?? "4px"}}
    />;
  }

  const classes = [
    "navigation-drawer",
    `${props.open ? "opened" : "closed"}`,
    `${hideChrome ? "hidden" : ""}`,
    props.resizable ? "resizable" : undefined
  ].join(" ");

  return <Drawer
    variant={"permanent"}
    sx={{display: hideChrome ? "none" : "block"}}
    open={props.open}
    ModalProps={{keepMounted: true}}
    className={classes}
  >

    <div className="navigation-drawer-container">
      <div ref={content} className="navigation-drawer-content" style={{width: props.open ? `${width}px` : "3.5em"}}>
        <Toolbar sx={{padding: "0 !important"}}>
          <DrawerToggleButton disableRipple onClick={props.toggle}>
            {props.open ? <MenuOpen /> : <Menu />}
          </DrawerToggleButton>
          <Logo onClick={navigateToDefault} />
        </Toolbar>
        <Navigation open={props.open} onOpen={onOpen} />
      </div>
      {renderHandle()}
    </div>

    <Suspended visible={!initialized} />

  </Drawer>;

}
