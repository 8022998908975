/**
 * Copyright ©2022 Drivepoint
 */

const constants = {
  drawers: {
    navigation: {
      expandedWidth: "20rem",
      collapsedWidth: "3.5rem",
      indent: "0.5rem"
    },
    configuration: {
      width: "20rem"
    }
  }
};

export default constants;
